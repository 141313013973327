import canUseDom from './dom';
import { ENDPOINTS } from 'config/api';
import { getJsonStr } from 'app/utilities/format';

import { url } from 'rfa-react-core';

const CMS_PAGE_DEPTH = 2;

export const getSlug = (reqUrl) => {
    return url.getSlug(reqUrl);
};

export const getParent = (reqUrl) => {
    return url.getParent(reqUrl);
};

export const getEntityType = (reqUrl) => {
    const arr = reqUrl.split('/');
    const base = arr.length > CMS_PAGE_DEPTH ? arr[1] : '';

    switch (base) {
        case 'event':
            return 'event';
        case 'show':
            return 'show';
        case 'venue':
            return 'venue';
        case 'news':
            return 'news';
        default:
            return 'page';
    }
};

export const getEndpoint = (entityType) => {
    switch (entityType) {
        case 'event':
            return ENDPOINTS.EVENT;
        case 'show':
            return ENDPOINTS.SHOWS;
        case 'venue':
            return ENDPOINTS.VENUES;
        case 'news':
            return ENDPOINTS.NEWS_PAGE;
        default:
            return ENDPOINTS.PAGES;
    }
};

export const getApiUrl = (reqUrl) => {
    return url.getApiUrl(reqUrl, getEntityType, getEndpoint);
};

export const getEntity = (reqUrl) => {
    const arr = reqUrl.split('/');
    if (arr.length <= 1) return 'pages';

    return 'pages';
};

export const getRouteArray = (reqUrl) => {
    if (reqUrl === '/news') return ['news', 'home'];

    return url.getRouteArray(reqUrl);
};

export const buildUrl = (path, params) => {
    const paramsString = Object.keys(params)
        .filter((param) => Boolean(params[param]))
        .map((param) => {
            return `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`;
        })
        .join('&');

    return `${path}${paramsString ? '?' : ''}${paramsString}`;
};

/**
 * `Entity` is one of the concepts from `CMS`, it looks like a `category` under every `project` inside `CMS`.
 *
 * @param {any} entityType
 * @return {array}
 */
const getEndpointArrByEntity = (entityType) => {
    const eventEnpoints = [{
        dataKey: 'page',
        endPoint: ENDPOINTS.EVENT,
        apiUrl: ''
    }];

    const showEnpoints = [{
        dataKey: 'page',
        endPoint: ENDPOINTS.SHOWS,
        apiUrl: ''
    }];

    const venueEnpoints = [{
        dataKey: 'page',
        endPoint: ENDPOINTS.VENUES,
        apiUrl: ''
    }];

    const newsEnpoints = [{
        dataKey: 'page',
        endPoint: ENDPOINTS.NEWS_PAGE,
        apiUrl: ''
    }];

    const pageEnpoints = [{
        dataKey: 'page',
        endPoint: ENDPOINTS.PAGES,
        apiUrl: ''
    }];

    switch (entityType) {
        case 'event':
            return eventEnpoints;
        case 'show':
            return showEnpoints;
        case 'venue':
            return venueEnpoints;
        case 'news':
            return newsEnpoints;
        default:
            return pageEnpoints;
    }
};

const getApiRequest = (reqUrl, entityTypeHandler, endpointHandler) => {
    const entityType = entityTypeHandler(reqUrl);
    const endpointArr = endpointHandler(entityType);
    const slug = getSlug(reqUrl);

    if (!canUseDom()) {
        console.log('getApiRequest', 'entityType', entityType);
        console.log('getApiRequest', 'endpointArr', getJsonStr(endpointArr));
    }

    // Calulate the `apiUrl` for each endpoit
    const requestEndpoints = endpointArr.map((tempEndpoint) => {
        // `enpointArr[?].enpoint` can be `string | function`
        if (typeof tempEndpoint.endPoint === 'function') {
            let apiUrl = '';

            if (tempEndpoint.endPoint === ENDPOINTS.PAGES) {
                apiUrl = tempEndpoint.endPoint(slug, getParent(reqUrl));
            } else {
                apiUrl = tempEndpoint.endPoint(slug);
            }

            return Object.assign({}, tempEndpoint, { apiUrl: apiUrl });
        }

        return Object.assign({}, tempEndpoint, { apiUrl: tempEndpoint.endPoint });
    });

    return requestEndpoints;
};

export const getApiRequestArr = (reqUrl) => {
    return getApiRequest(reqUrl, getEntityType, getEndpointArrByEntity);
};

export const getOpenGraphSlug = (slug, parent, type) => {
    if (slug === 'home') return '';

    if (type === 'shows') {
        return `show/${slug}`;
    }

    if (type === 'events') {
        return `event/${slug}`;
    }

    if (type === 'venues') {
        return `venue/${slug}`;
    }

    if (type === 'news') {
        return `news/${slug}`;
    }

    return parent ? `${parent}/${slug}` : slug;
};

// trim forward slashes and cast to lower case
export const normalizePath = (path) => {
    return path.replace(/^\/+/, '').toLowerCase();
};
