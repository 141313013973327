import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationBanner = ({ closeNotification, notification, closable }) => {
    const { id, type, title, description, url } = notification;

    const getNotificationType = (type) => {
        switch (type) {
            case 'danger': return 'danger';
            case 'cancellation': return 'cancel';
            case 'discount': return 'discount';
            case 'maintenance': return 'maintain';
            case 'venue-change': return 'venue-change';
            default: return type;
        }
    };

    return (
        <div className={`notification type-${getNotificationType(type)}`} key={id}>
            <div className="inner || constrain-width large">
                <Icon className="icon notification-icon" name={getNotificationType(type)} />
                <p className="title"><strong>{title}</strong></p>
                {url && description && <a className="description" href={url}>{description}</a>}
                {!url && description && <p className="description">{description}</p>}

                {closable && (
                    <button className="button || notification-toggle-button" onClick={() => { closeNotification(id); }}>
                        <Icon className="icon" name="close" />
                    </button>
                )}
            </div>
        </div>
    );
};

NotificationBanner.defaultProps = {
    closable: true
};

NotificationBanner.propTypes = {
    closeNotification: PropTypes.func,
    notification: PropTypes.object.isRequired,
    closable: PropTypes.bool,
};

export default NotificationBanner;
