/* eslint-disable require-jsdoc */
import { fireDelayedHistoryChange } from 'app/utilities/gtm';
import { getPageDataAction } from 'app/ducks/page';
import { LOCATION_CHANGE } from 'redux-first-history';
import selectPage from 'app/selectors/page';
import { startedRoutingAction } from 'app/ducks/status';

function createPreRouteMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        const { payload, type } = action;

        if (type === LOCATION_CHANGE) {
            const location = payload && payload.location ? payload.location : { pathname: '' };
            const pathname = location.pathname;

            const state = getState();
            const page = selectPage(state, pathname);

            if (page) {
                next(action);

                // On every route change we fire the GTM event 'delayedHistoryChange'.
                fireDelayedHistoryChange();
            } else {
                dispatch([
                    startedRoutingAction(),
                    getPageDataAction(location)
                ]);
            }
        } else {
            next(action);
        }
    };
}

const preRouteMiddleware = createPreRouteMiddleware();

export default preRouteMiddleware;
